import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '../stories/Typography'
import Button from '../stories/Button'
import { formatPhoneNumberForProfile } from '@app/libs/formatters'

import { useWhiteLabelingContext } from '../context/WhiteLabelingContext'

import { useMapEffects } from '../context/MapEffects'

const PlanAdminProviderSearchCard = ({
  data = { firstname: '', middlename: '', lastname: '', statesLicensed: [] },
  onClick = null,
  hideButton = false,
}) => {
  const {
    name,
    firstname,
    middlename,
    lastname,
    statesLicensed = [],
    npi,
    phone,
    taxonomy,
    zeroCostProvider
  } = data

  const { specialization } = taxonomy || {}

  const { planColorPrimary } = useWhiteLabelingContext()

  const { handleHover, handleClick } = useMapEffects()

  console.log('data', data)

  const fakeZeroCostProvider = Math.random() > 0.5

  return (
    <Box
      onMouseEnter={() => handleHover(data)}
      onMouseLeave={() => handleHover(null)}
      width='100%'
      padding='16px'
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      sx={{
        '&:hover': {
          bgcolor:
            `${planColorPrimary}22` || '#652d9222',
        },
      }}
    >
      <Box>
        {zeroCostProvider && (
          <Box
            width='min-content'
            marginLeft='-4px'
            backgroundColor={planColorPrimary || '#652d92'}
            style={{
              transform: 'skew(-10deg)',
            }}
          >
            <Typography
              variant='h5'
              style={{
                color: 'white',
                padding: '2px 8px',
                margin: '0px 0px 4px 0px',
                whiteSpace: 'nowrap',
                fontSize: '14px',
              }}
            >
              Zero Cost Provider
            </Typography>
          </Box>
        )}
        <Box>
          <Typography
            variant='body1'
            weight='bold'
            style={{
              color: planColorPrimary || '#652d92',
              // Make the text Normal capitalization
              textTransform: 'capitalize',
            }}
          >
            {name ? (
              name.toLowerCase()
            ) : (
              <>
                {(firstname ? firstname + ' ' : '').toLowerCase()}
                {(middlename ? middlename + ' ' : '').toLowerCase()}
                {(lastname ? lastname : '').toLowerCase()}
              </>
            )}
          </Typography>
        </Box>
        <Box paddingLeft='4px'>
          {statesLicensed.length > 0 && (
            <Typography
              variant='body1'
              style={{
                color: planColorPrimary ? `${planColorPrimary}dd` : '#652d92dd',
                fontSize: '14px',
              }}
            >
              <b>States Licensed:</b> {statesLicensed.join(', ')}
            </Typography>
          )}
          {specialization && (
            <Typography
              variant='body1'
              style={{
                color: planColorPrimary ? `${planColorPrimary}dd` : '#652d92dd',
                fontSize: '14px',
              }}
            >
              <b>Specialization:</b> {specialization}
            </Typography>
          )}
          {npi && (
            <Typography
              variant='body1'
              style={{
                color: planColorPrimary ? `${planColorPrimary}dd` : '#652d92dd',
                fontSize: '14px',
                // Make the text Normal capitalization
                textTransform: 'capitalize',
              }}
            >
              <b>NPI:</b> {npi}
            </Typography>
          )}
          <Typography
            variant='body1'
            style={{
              color: planColorPrimary ? `${planColorPrimary}dd` : '#652d92dd',
              fontSize: '14px',
            }}
          >
            <b>Phone:</b> {formatPhoneNumberForProfile(phone)}
          </Typography>
        </Box>
      </Box>
      <Box
        display='flex'
        justifyContent='space-between'
        height='100%'
        alignItems='center'
        sx={{
          flexDirection: {
            xs: 'row',
            sm: 'row',
            md: 'column',
            lg: 'column',
          },
        }}
        className='provider-search-card-button-container'
      >
        {!hideButton && (
          <Button
            variant='unstyled'
            style={{
              height: '40px',
              margin: '4px',
              width: '120px',
              backgroundColor: planColorPrimary || '#652d92',
            }}
            onClick={() => window?.Intercom('show')}
            className='provider-search-card-button'
          >
            <Typography
              variant='h5'
              weight='light'
              style={{
                margin: '0px',
                whiteSpace: 'nowrap',
                color: 'white',
              }}
            >
              Get Started
            </Typography>
          </Button>
        )}
      </Box>
    </Box >
  )
}

PlanAdminProviderSearchCard.propTypes = {
  data: PropTypes.shape({
    firstname: PropTypes.string,
    middlename: PropTypes.string,
    lastname: PropTypes.string,
    statesLicensed: PropTypes.array,
  }),
  onClick: PropTypes.string,
}

export default PlanAdminProviderSearchCard
